import React, { useCallback, useState, useEffect } from 'react';
import clsx from 'clsx';
import { HeaderStyles } from '../../templates/calculator/EvalCalculatorStyles';
import Icon from '../../styles/atoms/icons';

const HeaderBusinessCalculator = ({ currentStep }) => {
  const [sticky, setSticky] = useState(false);

  const handleScroll = useCallback(() => {
    // eslint-disable-next-line no-unused-expressions
    window.scrollY > 1 ? setSticky(true) : setSticky(false);
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleScroll);
    }
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);
  return (
    <HeaderStyles
      className={clsx(sticky && 'sticky')}
      currentStep={currentStep}
    >
      <div className="container top-bar">
        <Icon id="sm-dark-logo" className="nav-logo" isImage />
      </div>
    </HeaderStyles>
  );
};
export default HeaderBusinessCalculator;
